import React, { useState } from 'react';
import { operationDataByQuery } from '../../utils/helpers.js';
import './SearchForm.css';

const SearchForm = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const opNames = [];

  operationDataByQuery.forEach((operation) =>  {
    opNames.push(operation.name);
    console.log(operation);
  } );

  const handleChange = (event) => {
    console.log(event.target.value);
    setInputValue(event.target.value);

    if (event.target.value.length >= 3) {

      setSuggestions(
        opNames.filter((key) =>
          key.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
    } else {
      setSuggestions([]);
    }

  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setSuggestions([]);
    onSubmit(suggestion);
  };

  return (
    <form className="search-form">
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter operation or body part"
      />
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </form>
  );
};

export default SearchForm;


