// components/Home.js
import React, { useContext } from 'react';
import SearchForm from '../SearchForm/SearchForm';
import { fetchOperationData } from '../../utils/helpers';
import Results from '../Results/Results';
import { useState } from 'react';

const Home = () => {
  const [selectedOperation, setSelectedOperation] = useState("");

  const handleSearchSubmit = (operation) => {
    setSelectedOperation(operation);
  };

  return (
    <div className="app">
      <div className="app-content">
        <h1 className="app-title">Anaesthetic Reference</h1>
        <SearchForm onSubmit={handleSearchSubmit} />
        <div className="results-container">
          <Results selectedOperation={fetchOperationData(selectedOperation)}/>
        </div>
      </div>
    </div>
  );
};

export default Home;