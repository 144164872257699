import React, { useState } from 'react';
import { fetchBlockData } from './utils/blocks';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlockDetails from './components/BlockDetails/BlockDetails';
import HomePage from './components/HomePage/HomePage';

import './App.css';

function App() {
  const blockData = (name) => fetchBlockData(name);

return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/block/:blockName" element={<BlockDetails blockData={blockData} />} />
      </Routes>
    </Router>
  );
}

export default App;

