import blocksData from './blocks';

export const operationDataByQuery = [
  {
        id: "",
        name: "Laparoscopic Appendicectomy",
        specialty: "General Surgery",
        description: 'Laparoscopic appendicectomy is a surgical procedure to remove the appendix through a small incision in abdomen.',
        duration: "20 to 40 minutes",
        pain: "Low",
        blocks: "TAP Block, Local Anaesthetic to Port Sites, Ilioinguinal/Iliohypogastric Block",
        anaesthetic: "RSI, ETT, IPPV",
        other: "None",
  },
  {
    id: "",
    name: "Laparoscopic Cholecystectomy",
    specialty: "General Surgery",
    description: 'Laparoscopic cholecystectomy is a minimally invasive surgical procedure to remove the gallbladder using small incisions in the abdomen.',
    duration: "60 to 90 minutes",
    pain: "Low",
    blocks: "Subcostal TAP Block, Local Anaesthetic to Port Sites",
    anaesthetic: "RSI, ETT, IPPV",
    other: "None",
    },
    {
    id: "",
    name: "Right Hemicolectomy",
    specialty: "General Surgery",
    description: 'Right hemicolectomy is a surgical procedure to remove the right side of the colon, often performed to treat colon cancer or inflammatory bowel disease.',
    duration: "2 to 3 hours",
    pain: "Moderate",
    blocks: "Epidural, TAP Block, Local Anaesthetic Infiltration",
    anaesthetic: "GA, ETT, IPPV",
    other: "None",
    },
    {
    id: "",
    name: "Left Hemicolectomy",
    specialty: "General Surgery",
    description: 'Left hemicolectomy is a surgical procedure to remove the left side of the colon, often performed to treat colon cancer or inflammatory bowel disease.',
    duration: "2 to 3 hours",
    pain: "Moderate",
    blocks: "Epidural, TAP Block, Local Anaesthetic Infiltration",
    anaesthetic: "GA, ETT, IPPV",
    other: "None",
    },
    {
    id: "",
    name: "Hartmann's Procedure",
    specialty: "General Surgery",
    description: "Hartmann's procedure is a surgery that involves the removal of a portion of the sigmoid colon and creating an end colostomy, typically performed for complicated diverticulitis or bowel obstruction.",
    duration: "2 to 3 hours",
    pain: "Moderate",
    blocks: "Epidural, TAP Block, Local Anaesthetic Infiltration",
    anaesthetic: "GA, ETT, IPPV",
    other: "None",
    },
    {
    id: "",
    name: "Upper GI Endoscopy",
    specialty: "Gastroenterology",
    description: 'Upper GI endoscopy, or esophagogastroduodenoscopy (EGD), is a procedure that involves the insertion of an endoscope through the mouth to examine the esophagus, stomach, and duodenum.',
    duration: "15 to 30 minutes",
    pain: "Minimal",
    blocks: "None",
    anaesthetic: "Sedation, Topical Pharyngeal Anesthesia",
    other: "None",
    },
    {
    id: "",
    name: "Colonoscopy",
    specialty: "Gastroenterology",
    description: 'Colonoscopy is a procedure that involves the insertion of a colonoscope through the rectum to examine the entire colon.',
    duration: "30 to 60 minutes",
    pain: "Minimal",
    blocks: "None",
    anaesthetic: "Sedation",
    other: "None",
    },
    {
      id: "",
      name: "Arthroscopic Anterior Cruciate Ligament Reconstruction",
      specialty: "Orthopedic Surgery",
      description: "A minimally invasive surgical procedure to reconstruct the torn anterior cruciate ligament (ACL) in the knee using an arthroscopic technique.",
      duration: "60 to 120 minutes",
      pain: "Moderate",
      blocks: "Femoral nerve block, sciatic nerve block",
      anaesthetic: "Spinal or general anaesthesia",
      other: "Postoperative pain management and physical therapy"
    },
    {
      id: "",
      name: "Arthroscopic Meniscal Repair of the Knee",
      specialty: "Orthopedic Surgery",
      description: "A minimally invasive surgical procedure to repair a torn meniscus in the knee using an arthroscopic technique.",
      duration: "60 to 90 minutes",
      pain: "Moderate",
      blocks: "Femoral nerve block, sciatic nerve block",
      anaesthetic: "Spinal or general anaesthesia",
      other: "Postoperative pain management and physical therapy"
    },
    {
      id: "",
      name: "Arthroscopic Partial Meniscectomy of the Knee",
      specialty: "Orthopedic Surgery",
      description: "A minimally invasive surgical procedure to remove a part of a damaged meniscus in the knee using an arthroscopic technique.",
      duration: "45 to 90 minutes",
      pain: "Moderate",
      blocks: "Femoral nerve block, sciatic nerve block",
      anaesthetic: "Spinal or general anaesthesia",
      other: "Postoperative pain management and physical therapy"
    },
    {
      id: "",
      name: "Arthroscopic Rotator Cuff Repair",
      specialty: "Orthopedic Surgery",
      description: "A minimally invasive surgical procedure to repair a torn rotator cuff in the shoulder using an arthroscopic technique.",
      duration: "60 to 120 minutes",
      pain: "Moderate",
      blocks: "Interscalene brachial plexus block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and physical therapy"
    },
    {
      id: "",
      name: "Arthroscopic Subacromial Decompression",
      specialty: "Orthopedic Surgery",
      description: "A minimally invasive surgical procedure to relieve impingement in the shoulder joint by removing bone spurs and inflamed tissue using an arthroscopic technique.",
      duration: "60 to 90 minutes",
      pain: "Moderate",
      blocks: "Interscalene brachial plexus block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and physical therapy"
    },
    {
      id: "",
      name: "Carpal Tunnel Decompression",
      specialty: "Orthopedic Surgery",
      description: "A surgical procedure to relieve pressure on the median nerve in the wrist by cutting the transverse carpal ligament, which is causing carpal tunnel syndrome.",
      duration: "30 to 60 minutes",
      pain: "Low",
      blocks: "Axillary or infraclavicular brachial plexus block",
      anaesthetic: "Local or regional anaesthesia",
      other: "Postoperative pain management and occupational therapy"
    },
    {
      id: "",
      name: "Lumbar Spine Decompression",
      specialty: "Neurosurgery / Orthopedic Surgery",
      description: "A surgical procedure to remove structures, such as bone spurs or herniated discs, that are compressing nerves in the lumbar spine.",
      duration: "60 to 180 minutes",
      pain: "Moderate",
      blocks: "Epidural anaesthesia or lumbar plexus block",
      anaesthetic: "General or regional, depending on the surgical approach",
      other: "Postoperative pain management and physical therapy"
    },
    {
      id: "",
      name: "Lumbar Spine Fusion",
      specialty: "Neurosurgery / Orthopedic Surgery",
      description: "A surgical procedure to fuse two or more vertebrae in the lumbar spine, which can help stabilize the spine and alleviate pain.",
      duration: "180 to 360 minutes",
      pain: "Moderate to high",
      blocks: "Epidural anaesthesia or lumbar plexus block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management, physical therapy, and potential brace use"
    },
    {
      id: "",
      name: "Total Hip Replacement (THR)",
      specialty: "Orthopedic Surgery",
      description: "A surgical procedure to replace a damaged hip joint with a prosthetic implant to relieve pain and improve mobility.",
      duration: "90 to 180 minutes",
      pain: "Moderate to high",
      blocks: "Lumbar plexus block or fascia iliaca block",
      anaesthetic: "General or spinal anaesthesia",
      other: "Postoperative pain management, physical therapy, and potential mobility aids"
    },
    {
      id: "",
      name: "Total Knee Replacement (TKR)",
      specialty: "Orthopedic Surgery",
      description: "A surgical procedure to replace a damaged knee joint with a prosthetic implant to relieve pain and improve mobility.",
      duration: "90 to 180 minutes",
      pain: "Moderate to high",
      blocks: "Femoral nerve block, sciatic nerve block, adductor canal block",
      anaesthetic: "General or spinal anaesthesia",
      other: "Postoperative pain management, physical therapy, and potential mobility aids"
    },
    {
      id: "",
      name: "Tonsillectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove the tonsils, which are lymphoid tissues located in the back of the throat.",
      duration: "30 to 60 minutes",
      pain: "Moderate",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and monitoring for bleeding"
    },
    {
      id: "",
      name: "Adenoidectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove the adenoids, which are lymphoid tissues located behind the nasal cavity.",
      duration: "30 to 45 minutes",
      pain: "Low to moderate",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and monitoring for bleeding"
    },
    {
      id: "",
      name: "Myringotomy and Tympanostomy Tube Placement",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure that involves making a small incision in the eardrum and inserting a tiny tube to drain fluid and prevent recurrent ear infections.",
      duration: "15 to 30 minutes",
      pain: "Low",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and monitoring for complications"
    },
    {
      id: "",
      name: "Septoplasty",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to straighten the nasal septum, which can improve breathing and alleviate symptoms of a deviated septum.",
      duration: "60 to 90 minutes",
      pain: "Low to moderate",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General or local anaesthesia",
      other: "Postoperative pain management, nasal packing, and monitoring for complications"
    },
    {
      id: "",
      name: "Sinus Surgery (FESS)",
      specialty: "Otolaryngology (ENT)",
      description: "A minimally invasive surgical procedure that uses an endoscope to remove blockages in the sinuses, which can alleviate symptoms of chronic sinusitis.",
      duration: "60 to 120 minutes",
      pain: "Low to moderate",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management, nasal packing, and monitoring for complications"
    },
    {
      id: "",
      name: "Turbinectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove or reduce the size of the turbinates, which are structures in the nasal passages that warm and moisten inhaled air.",
      duration: "30 to 60 minutes",
      pain: "Low to moderate",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General or local anaesthesia",
      other: "Postoperative pain management, nasal packing, and monitoring for complications"
    },
    {
      id: "",
      name: "Mastoidectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove infected mastoid air cells in the mastoid bone, which is located behind the ear. This procedure is typically performed to treat chronic otitis media or cholesteatoma.",
      duration: "2 to 4 hours",
      pain: "Low to moderate",
      blocks: "Postauricular nerve block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and monitoring for complications"
    },
    {
      id: "",
      name: "Parotidectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove part or all of the parotid gland, which is a major salivary gland located in front of the ear. This procedure is typically performed to treat parotid gland tumors or chronic infections.",
      duration: "2 to 4 hours",
      pain: "Moderate",
      blocks: "Superficial cervical plexus block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management, monitoring for facial nerve injury, and potential salivary fistula"
    },
    {
      id: "",
      name: "Thyroidectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove part or all of the thyroid gland, which is located in the neck. This procedure is typically performed to treat thyroid cancer, goiter, or hyperthyroidism.",
      duration: "2 to 4 hours",
      pain: "Moderate",
      blocks: "Superficial cervical plexus block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management, monitoring for recurrent laryngeal nerve injury, and potential hypocalcemia"
    },
    {
      id: "",
      name: "Laryngoscopy and Biopsy",
      specialty: "Otolaryngology (ENT)",
      description: "A diagnostic procedure that involves using an endoscope to visualize the larynx (voice box) and obtain a tissue sample for biopsy. This procedure is typically performed to diagnose laryngeal disorders or cancer.",
      duration: "15 to 45 minutes",
      pain: "Low",
      blocks: "Topical anaesthesia",
      anaesthetic: "General or local anaesthesia",
      other: "Postoperative pain management and monitoring for complications"
    },
    {
      id: "",
      name: "Stapedectomy",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to remove the stapes bone in the middle ear and replace it with a prosthesis. This procedure is typically performed to treat conductive hearing loss caused by otosclerosis.",
      duration: "1 to 2 hours",
      pain: "Low",
      blocks: "Local infiltration anaesthesia",
      anaesthetic: "General or local anaesthesia",
      other: "Postoperative pain management and monitoring for complications, such as hearing loss or vertigo"
    },
    {
      id: "",
      name: "Direct Laryngoscopy and Bronchoscopy",
      specialty: "Otolaryngology (ENT)",
      description: "A diagnostic and therapeutic procedure that involves using an endoscope to visualize the larynx (voice box) and bronchi (airways) for evaluating airway disorders or removing foreign bodies.",
      duration: "30 to 60 minutes",
      pain: "Low",
      blocks: "Topical anaesthesia",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management and monitoring for complications"
    },
    {
      id: "",
      name: "Cochlear Implant Surgery",
      specialty: "Otolaryngology (ENT)",
      description: "A surgical procedure to implant a cochlear device, which is an electronic device that can provide a sense of sound to individuals with severe to profound sensorineural hearing loss.",
      duration: "2 to 4 hours",
      pain: "Low to moderate",
      blocks: "Postauricular nerve block",
      anaesthetic: "General anaesthesia",
      other: "Postoperative pain management, monitoring for complications, and device programming"
    }
    
    

    
    
    

    

    
    

    

    
    
    
    
    

    

    

    

    

    
    
    
    

];


  // 'hernia repair': ['TAP block', 'Ilioinguinal/Iliohypogastric block'],
  // 'caesarean section': ['Spinal anesthesia', 'Epidural anesthesia'],
  // 'knee arthroscopy': ['Femoral nerve block', 'Sciatic nerve block', 'Adductor canal block'],
  // 'hip replacement': ['Lumbar plexus block', 'Fascia iliaca block', 'Sciatic nerve block'],
  // 'knee replacement': ['Femoral nerve block', 'Sciatic nerve block', 'Adductor canal block'],
  // 'shoulder arthroscopy': ['Interscalene brachial plexus block', 'Suprascapular nerve block'],
  // 'ankle arthroscopy': ['Popliteal sciatic nerve block', 'Saphenous nerve block'],
  // 'elbow arthroscopy': ['Infraclavicular brachial plexus block', 'Axillary Brachial Plexus Block'],
  // 'wrist arthroscopy': ['Axillary Brachial Plexus Block'],
  // 'carpal tunnel release': ['Axillary Brachial Plexus Block', 'Wrist block'],
  // 'rotator cuff repair': ['Interscalene brachial plexus block', 'Suprascapular nerve block'],
  // 'ACL reconstruction': ['Femoral nerve block', 'Sciatic nerve block', 'Adductor canal block'],
  // 'meniscectomy': ['Femoral nerve block', 'Sciatic nerve block', 'Adductor canal block'],
  // 'ankle fracture fixation': ['Popliteal sciatic nerve block', 'Saphenous nerve block'],
  // 'tibial fracture fixation': ['Popliteal sciatic nerve block', 'Femoral nerve block'],
  // 'humerus fracture fixation': ['Interscalene brachial plexus block'],
  // 'radial fracture fixation': ['Infraclavicular brachial plexus block', 'Axillary Brachial Plexus Block'],
  // 'ulnar fracture fixation': ['Infraclavicular brachial plexus block', 'Axillary Brachial Plexus Block'],
  // 'hammertoe surgery': ['Popliteal sciatic nerve block', 'Saphenous nerve block'],
  // 'ganglion cyst removal': ['Axillary Brachial Plexus Block', 'Wrist block'],
  // 'Achilles tendon repair': ['Popliteal sciatic nerve block'],
  // 'hallux valgus correction': ['Popliteal sciatic nerve block', 'Saphenous nerve block'],
  // 'spinal fusion': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'laminectomy': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'anterior cervical discectomy and fusion': ['Deep cervical plexus block'],
  // 'lumbar discectomy': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'microdiscectomy': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'decompression surgery': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'kyphoplasty': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'vertebroplasty': ['Epidural anesthesia', 'Spinal anesthesia'],
  // 'appendectomy': ['TAP block', 'Ilioinguinal/Iliohypogastric block'],
  // 'inguinal hernia repair': ['TAP block', 'Ilioinguinal/Iliohypogastric block'],
  // 'cholecystectomy': ['TAP block'],
  // 'cesarean section': ['Spinal anesthesia', 'Epidural anesthesia'],
  // 'hysterectomy': ['TAP block', 'Epidural anesthesia', 'Spinal anesthesia'],
  // 'mastectomy': ['Pectoral nerve block', 'Paravertebral block', 'Intercostal block'],
  // 'lumpectomy': ['Pectoral nerve block', 'Paravertebral block', 'Intercostal block'],
  // 'thyroidectomy': ['Superficial cervical plexus block', 'Deep cervical plexus block'],
  // 'colectomy': ['TAP block', 'Epidural anesthesia'],
  // 'hemorrhoidectomy': ['Pudendal nerve block', 'Caudal anesthesia'],
  // 'inguinal orchiectomy': ['Ilioinguinal/Iliohypogastric block'],
  // 'varicocelectomy': ['Ilioinguinal/Iliohypogastric block'],
  // 'vasectomy': ['Ilioinguinal/Iliohypogastric block'],
  // 'tubal ligation': ['TAP block'],
  // 'ovarian cystectomy': ['TAP block', 'Epidural anesthesia', 'Spinal anesthesia'],
  // 'myomectomy': ['TAP block', 'Epidural anesthesia', 'Spinal anesthesia'],
  // 'endometrial ablation': ['Paracervical block', 'Epidural anesthesia', 'Spinal anesthesia'],
  // 'breast augmentation': ['Pectoral nerve block', 'Paravertebral block', 'Intercostal block'],
  // 'breast reduction': ['Pectoral nerve block', 'Paravertebral block', 'Intercostal block'],
  // 'kidney stone removal': ['Paravertebral block', 'TAP block', 'Epidural anesthesia'],
  // 'ureteral stent placement': ['Paravertebral block', 'TAP block', 'Epidural anesthesia'],
  // 'Head': ['Scalp block'],
  //   'Neck': ['Cervical plexus block', 'Superficial Cervical Plexus Block'],
  //   'Shoulder': ['Interscalene brachial plexus block'],
  //   'Upper arm': ['Infraclavicular brachial plexus block'],
  //   'Elbow': ['Supraclavicular brachial plexus block', 'Axillary Brachial Plexus Block'],
  //   'Forearm': ['Distal upper limb blocks', 'Radial nerve block', 'Ulnar nerve block', 'Median nerve block'],
  //   'Wrist': ['Axillary Brachial Plexus Block', 'Brachial plexus block'],
  //   'Hand': ['Wrist Block', 'Digital nerve block'],
  //   'Finger': ['Digital nerve block'],
  //   'Breast': ['Pectoral nerve block', 'Serratus anterior plane block'],
  //   'Sternum': ['Parasternal block'],
  //   'Thorax': ['Erector Spinae Block', 'Paravertebral block', 'Intercostal block'],
  //   'Upper abdomen': ['Subcostal TAP block', 'Rectus sheath block'],
  //   'Midline abdomen': ['Rectus Sheath', 'TAP block', 'Quadratus lumborum block'],
  //   'Hip': ['Fascia iliaca block', 'Lumbar plexus block'],
  //   'Femur': ['Fascia iliaca block', 'Lumbar plexus block'],
  //   'Knee': ['Femoral nerve block', 'Adductor canal block'],
  //   'Ankle': ['Femoral nerve block', 'Popliteal sciatic nerve block', 'Ankle Block'],
  //   'Foot': ['Popliteal sciatic nerve block', 'Ankle Block'],
  //   'Toe': ['Digital nerve block'],

// const blockLinks = {
//   'Ankle Block': 'https://www.youtube.com/watch?v=3FY_ceCDm7o&pp=ygUQYW5rbGUgYmxvY2sgcmF1aw%3D%3D',
//   'Axillary Brachial Plexus Block': 'https://www.youtube.com/watch?v=cmJTzVr7dyM&pp=ygUTYXhpbGxhcnkgYmxvY2sgcmF1aw%3D%3D',
//   'Superficial Cervical Plexus Block': 'https://www.youtube.com/watch?v=hmfvUldlvfY&pp=ygUaQ2VydmljYWwgUGxleHVzIEJsb2NrIHJhdWs%3D',
//   'Scalp block': 'https://www.youtube.com/watch?v=5mTEa7ZdM_g',
//   'Interscalene brachial plexus block': 'https://www.youtube.com/watch?v=h_30mwMLH6M&pp=ygUdaW50ZXJzY2FsZW5lIGJsb2NrIHVsdHJhc291bmQ%3D',
//   'Infraclavicular brachial plexus block': 'https://www.youtube.com/watch?v=Uk-2pdFQ75A&pp=ygUgaW5mcmFjbGF2aWN1bGFyIGJsb2NrIHVsdHJhc291bmQ%3D',
//   'Supraclavicular brachial plexus block': 'https://www.youtube.com/watch?v=T5pQ0ZFldSU&pp=ygUgc3VwcmFjbGF2aWN1bGFyIGJsb2NrIHVsdHJhc291bmQ%3D',
//   'Wrist Block': 'https://www.youtube.com/watch?v=L83fcjc8be4',
//   'Distal upper limb blocks': 'https://www.youtube.com/watch?v=d24sbly6szQ',
//   'Radial nerve block': 'https://www.youtube.com/watch?v=d24sbly6szQ&pp=ygUdcmFkaWFsIG5lcnZlIGJsb2NrIHVsdHJhc291bmQ%3D',
//   'Ulnar nerve block': 'https://www.youtube.com/watch?v=d24sbly6szQ&pp=ygUdcmFkaWFsIG5lcnZlIGJsb2NrIHVsdHJhc291bmQ%3D',
//   'Median nerve block': 'https://www.youtube.com/watch?v=d24sbly6szQ&pp=ygUdcmFkaWFsIG5lcnZlIGJsb2NrIHVsdHJhc291bmQ%3D',
//   'Digital nerve block': 'https://www.youtube.com/watch?v=NThhhrdhC84&pp=ygUTZGlnaXRhbCBuZXJ2ZSBibG9jaw%3D%3D',
//   'Pectoral nerve block': 'https://www.youtube.com/watch?v=0g_PGLYy0gw&pp=ygUKcGVjcyBibG9jaw%3D%3D',
//   'Serratus anterior plane block': 'https://www.youtube.com/watch?v=vQSW-K-Fw5Y&t=100s&pp=ygUXc2VycmF0dXMgYW50ZXJpb3IgYmxvY2s%3D',
//   'Parasternal block': 'https://www.youtube.com/watch?v=Mw_YC_09vME&pp=ygURcGFyYXN0ZXJuYWwgYmxvY2s%3D',
//   'Paravertebral block': 'https://www.youtube.com/watch?v=D92ZbS8fMY0&pp=ygUecGFyYXZlcnRlYnJhbCBibG9jayB1bHRyYXNvdW5k',
//   'Intercostal block': 'https://www.nysora.com/topics/regional-anesthesia-for-specific-surgical-procedures/thorax/intercostal-nerve-block/',
//   'Erector Spinae Block': 'https://www.youtube.com/watch?v=EXq5BOBPj8c&pp=ygUUZXJlY3RvciBzcGluYWUgYmxvY2s%3D',
//   'Subcostal TAP block': 'https://www.youtube.com/watch?v=OqedcP9OPvc&pp=ygUec3ViY29zdGFsIHRhcCBibG9jayB1bHRyYXNvdW5k',
//   'Rectus sheath block': 'https://www.youtube.com/watch?v=WOyTeZK4Ys8&pp=ygUTcmVjdHVzIHNoZWF0aCBibG9jaw%3D%3D',
//   'TAP block': 'https://www.youtube.com/watch?v=XgWN-ACbHPE&pp=ygUJdGFwIGJsb2Nr',
//   'Lumbar plexus block': 'https://www.youtube.com/watch?v=i3AXB6mAoms&pp=ygUTbHVtYmFyIHBsZXh1cyBibG9jaw%3D%3D',
//   'Femoral nerve block': 'https://www.youtube.com/watch?v=tMy978ZwaDU&pp=ygUTZmVtb3JhbCBuZXJ2ZSBibG9jaw%3D%3D',
//   'Popliteal sciatic nerve block': 'https://www.youtube.com/watch?v=lUkTv5U0F18&pp=ygUTc2NpYXRpYyBuZXJ2ZSBibG9jaw%3D%3D',
//   'Fascia iliaca block': 'https://www.youtube.com/watch?v=T7Mp4t08tT8&pp=ygUeZmFzY2lhIGlsaWFjYSBibG9jayB1bHRyYXNvdW5k',
//   'Adductor canal block': 'https://www.youtube.com/watch?v=DZLjNHkbMtI&pp=ygUfYWRkdWN0b3IgY2FuYWwgYmxvY2sgdWx0cmFzb3VuZA%3D%3D',
  

// }
  
// export const fetchBlocks = (operation) => {
//   const operationData = blocksByQuery[operation];
//   if (!operationData) return [];

//   return operationData;
// };

export const fetchOperationData = (operationName) => {
  return operationDataByQuery.find((operation) => operation.name == operationName);
  // const operationData = operationDataByQuery.find((operation) => operation.name = operationName);
  // if (!operationData) return [];

  // const blocksReturnable = [];

  // operationData.forEach((blockName) => {
  //   const newBlockData = blocksData.find((block) => block.name === blockName);
  //   if (newBlockData) {
  //     blocksReturnable.push(newBlockData);
  //   } else {
  //     console.log(`Block not found: ${blockName}`);
  //   }
  // });

  // return blocksReturnable;
};




