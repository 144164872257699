const blocksData = [
  {
    id: 'd407ace9-5e5e-4a7d-b5a5-5b5f5a2510e0',
    name: 'Ankle Block',
    infoText: 'Indications: Foot surgeries and procedures, Transducer position: Around the ankle joint, Goal: Local anesthetic spread around the tibial, sural, deep peroneal, and saphenous nerves, Local anesthetic: 5-10 mL per nerve',
generalInfo: 'An ankle block provides anesthesia to the foot. It targets four nerves that supply the foot and involves the administration of local anesthetic around each nerve. The ankle block can be performed with or without ultrasound guidance and has minimal risk of complications when compared to other regional anesthetic blocks.',
    url: '',
    images: [],
    videos: [
      {
        src: '3FY_ceCDm7o',
        title: 'Ankle Block',
      },
    ],
  },
  {
    id: 'd9ac9b3e-8e6c-4926-bf3e-aea25a11f8c5',
    name: 'Axillary Brachial Plexus Block',
    infoText: 'Axillary brachial plexus blocks are utilized for upper limb surgeries below the shoulder. Key anatomical structures include the axillary artery, musculocutaneous, radial, median, and ulnar nerves.',
    url: '',
    images: [],
    videos: [
      {
        src: 'cmJTzVr7dyM',
        title: 'Axillary Brachial Plexus Block',
      },
    ],
  },
  { 
    id: 'fa0d8909-3e95-4c6e-9a3c-d6b37a6b8ca6',
    name: 'Superficial Cervical Plexus Block',
    infoText: 'Indications: Surgeries on the clavicle, neck, and superficial neck structures, Transducer position: Transverse to the neck, at the level of the midpoint of the posterior border of the sternocleidomastoid muscle, Goal: Local anesthetic spread around the superficial cervical plexus, Local anesthetic: 5-10 mL',
    generalInfo: 'A superficial cervical plexus block provides anesthesia to the skin and superficial structures of the neck. It involves the administration of local anesthetic along the superficial cervical plexus, located on the posterior border of the sternocleidomastoid muscle. Ultrasound guidance may be used, but it is not essential. The risk of complications is low, and the procedure is well-tolerated by patients.', 
    url: '',
    images: [],
    videos: [
      {
        src: 'hmfvUldlvfY',
        title: 'Superficial Cervical Plexus Block',
      },
    ],
  },
  { 
    id: '6d2ff6b7-6f46-4565-81d5-5f18b5c91b49',
    name: 'Scalp block',
    infoText: 'Indications: Surgeries of the scalp and forehead, Transducer position: Not applicable (landmark-based technique), Goal: Local anesthetic spread around the supraorbital, supratrochlear, zygomaticotemporal, auriculotemporal, lesser occipital, and greater occipital nerves, Local anesthetic: 3-5 mL per injection site',
    generalInfo: 'A scalp block provides anesthesia to the scalp and forehead. It involves the administration of local anesthetic at multiple injection sites to target the nerves that innervate the scalp. The scalp block is a landmark-based technique and does not typically require ultrasound guidance. The risk of complications is low, and patients generally tolerate the procedure well.',
    url: '',
    images: [],
    videos: [
      {
        src: '5mTEa7ZdM_g',
        title: 'Scalp Block',
      },
    ],
  },
  { id: '1eabc669-9e20-4b56-8d0a-dc37f6bcf49e',
    name: 'Interscalene brachial plexus block',
    infoText: 'Interscalene blocks are used for shoulder and upper arm surgeries. Relevant anatomy includes the brachial plexus roots and trunks, as well as the interscalene groove.',
    url: '',
    images: [],
    videos: [
      {
        src: 'h_30mwMLH6M',
        title: 'Interscalene Brachial Plexus Block',
      },
    ],
  },

   { 
    id: '40b7f800-df79-49b4-bf07-4f4d4b0c4b3e' ,
    name: 'Infraclavicular brachial plexus block',
    infoText: 'Indications: Surgeries of the forearm, wrist, and hand, Transducer position: Parasagittal to the clavicle, inferior to the midpoint of the clavicle, Goal: Local anesthetic spread around the brachial plexus cords, Local anesthetic: 20-30 mL',
    generalInfo: 'The infraclavicular block targets the brachial plexus cords beneath the clavicle and provides anesthesia for surgeries involving the forearm, wrist, and hand. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic around the brachial plexus cords. The procedure has a low risk of complications, such as pneumothorax, and is generally well-tolerated by patients.',
    url: '',
    images: [],
    videos: [
      {
        src: 'Uk-2pdFQ75A',
        title: 'Infraclavicular Brachial Plexus Block',
      },
    ],
  },
  {
    id: '3c7e9fb9-9e49-4e0a-bd81-6c8c6e205d6a',
    name: 'Supraclavicular brachial plexus block',
    infoText: 'Indications: Surgeries of the arm, forearm, and hand, Transducer position: Transverse to the clavicle, just above the midpoint of the clavicle, Goal: Local anesthetic spread around the brachial plexus trunks, Local anesthetic: 20-30 mL',
    generalInfo: 'The supraclavicular block targets the brachial plexus trunks above the clavicle and provides anesthesia for surgeries involving the arm, forearm, and hand. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic around the brachial plexus trunks.' ,
    url: '',
    images: [],
    videos: [
      {
        src: 'T5pQ0ZFldSU',
        title: 'Supraclavicular Brachial Plexus Block',
      },
    ],
  },
    {
    id: '9ea0e40e-6a0c-4f14-b5f7-5c8e0d5dbb29',
    name: 'Wrist Block',
    infoText: 'Indications: Hand and wrist surgeries, Transducer position: Around the wrist joint, Goal: Local anesthetic spread around the radial, ulnar, and median nervesations',
    generalInfo: 'A wrist block provides anesthesia to the hand and wrist. It targets three main nerves that supply the hand - the radial, ulnar, and median nerves. The administration of local anesthetic around each nerve can be performed with or without ultrasound guidance.',
    url: '',
    images: [],
    videos: [
      {
        src: 'L83fcjc8be4',
        title: 'Wrist Block',
      },
    ],
  },
  {
    id: '57a230a8-ae5d-4a81-97e7-6b8e94311db4',
    name: 'Distal upper limb blocks',
    infoText: 'Distal upper limb blocks target the radial, ulnar, and median nerves for forearm, wrist, and hand surgeries. The key anatomical landmarks include the humerus, radius, and ulna bones.',
    url: '',
    images: [],
    videos: [
      {
        src: 'd24sbly6szQ',
        title: 'Distal Upper Limb Blocks',
      },
    ],
  },
  {
    id: 'a388f32d-a87d-41e3-9c39-6d7b6b3a3b47',
    name: 'Radial nerve block',
    infoText: 'Indications: Surgeries and procedures involving the radial aspect of the hand and wrist, Transducer position: Around the elbow joint or forearm, Goal: Local anesthetic spread around the radial nerve, Local anesthetic: 5-10 mL',
    generalInfo: 'A radial nerve block provides anesthesia to the radial aspect of the hand and wrist. It targets the radial nerve and involves the administration of local anesthetic around the nerve. The radial nerve block can be performed with or without ultrasound guidance and has minimal risk of complications when compared to other regional anesthetic blocks.',
    url: '',
    images: [],
    videos: [
      {
        src: 'd24sbly6szQ',
        title: 'Radial Nerve Block',
      },
    ],
  },
  {
    id: '7998b0a6-9baf-4b46-90c0-df5d5dd5d6d5',
    name: 'Ulnar nerve block',
    infoText: 'Indications: Surgeries and procedures involving the ulnar aspect of the hand and wrist, Transducer position: Around the elbow joint or forearm, Goal: Local anesthetic spread around the ulnar nerve, Local anesthetic: 5-10 mL',
    generalInfo: 'An ulnar nerve block provides anesthesia to the ulnar aspect of the hand and wrist. It targets the ulnar nerve and involves the administration of local anesthetic around the nerve. The ulnar nerve block can be performed with or without ultrasound guidance and has minimal risk of complications when compared to other regional anesthetic blocks.',    url: '',
    images: [],
    videos: [
      {
        src: 'd24sbly6szQ',
        title: 'Ulnar Nerve Block',
      },
    ],
  },
  {
    id: 'b72a2b1f-9e6d-4926-853f-2a1e682f78a7',
    name: 'Median nerve block',
    infoText: 'Indications: Surgeries and procedures involving the palmar aspect of the hand and fingers, Transducer position: Around the elbow joint or forearm, Goal: Local anesthetic spread around the median nerve, Local anesthetic: 5-10 mL',
    generalInfo: 'A median nerve block provides anesthesia to the palmar aspect of the hand and fingers. It targets the median nerve and involves the administration of local anesthetic around the nerve. The median nerve block can be performed with or without ultrasound guidance and has minimal risk of complications when compared to other regional anesthetic blocks.',    url: '',
    images: [],
    videos: [
      {
        src: 'd24sbly6szQ',
        title: 'Median Nerve Block',
      },
    ],
  },
  {
    id: 'c755e8e7-6b9f-4a84-8a4f-7e4b4a4b7d4f',
    name: 'Digital nerve block',
    infoText: 'Indications: Surgeries and procedures involving individual fingers, Transducer position: Not applicable (landmark-based technique), Goal: Local anesthetic spread around the digital nerves, Local anesthetic: 1-3 mL per injection site',
    generalInfo: 'A digital nerve block provides anesthesia to individual fingers. It involves the administration of local anesthetic at multiple injection sites to target the digital nerves. The digital nerve block is a landmark-based technique and does not typically require ultrasound guidance. The risk of complications is low, and patients generally tolerate the procedure well.',    url: '',
    images: [],
    videos: [
      {
        src: 'NThhhrdhC84',
        title: 'Digital Nerve Block',
      },
    ],
  },
  {
    id: 'e7a62e19-3f7d-4e4d-a4f4-4c0a4ad22c17',
    name: 'Pectoral nerve block',
    infoText: 'Indications: Surgeries involving the chest wall and breast, Transducer position: Transverse to the chest, below the clavicle, Goal: Local anesthetic spread around the pectoral nerves, Local anesthetic: 10-15 mL',
    generalInfo: 'A pectoral nerve block provides anesthesia to the chest wall and breast. It targets the pectoral nerves and involves the administration of local anesthetic around the nerves. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic around the pectoral nerves. The procedure has a low risk of complications and is generally well-tolerated by patients.',    url: '',
    images: [],
    videos: [
      {
        src: '0g_PGLYy0gw',
        title: 'Pectoral Nerve Block',
      },
    ],
  },
  { 
    id: 'f0970a9d-7c1d-4826-8b96-efd49aef7e50',
    name: 'Serratus anterior plane block',
    infoText: 'Indications: Surgeries involving the lateral chest wall and rib fractures, Transducer position: Transverse to the chest, at the level of the mid-axillary line, Goal: Local anesthetic spread around the serratus anterior muscle, Local anesthetic: 20-30 mL',
    generalInfo: 'A serratus anterior plane block provides anesthesia to the lateral chest wall. It targets the intercostal nerves and involves the administration of local anesthetic around the serratus anterior muscle. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic. The procedure has a low risk of complications and is generally well-tolerated by patients.',    url: '',
    images: [],
    videos: [
      {
        src: 'vQSW-K-Fw5Y',
        title: 'Serratus Anterior Plane Block',
      },
    ],
  },
  {
    id: '6020b476-8e9c-4bbd-8f5e-cb5d5e5d5d5e',
    name: 'Parasternal block',
    infoText: 'Indications: Surgeries involving the anterior chest wall, Transducer position: Longitudinal to the sternum, at the level of the intercostal spaces, Goal: Local anesthetic spread around the intercostal nerves, Local anesthetic: 5-10 mL per space',
    generalInfo: 'A parasternal block provides anesthesia to the anterior chest wall. It targets the intercostal nerves and involves the administration of local anesthetic around the nerves in the intercostal spaces. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic. The procedure has a low risk of complications and is generally well-tolerated by patients.',    url: '',
    images: [],
    videos: [
      {
        src: 'Mw_YC_09vME',
        title: 'Parasternal Block',
      },
    ],
  },
  {
    id: '8b2e1d9c-5d6a-42a8-95ea-3e3f3b3e3b3e',
    name: 'Paravertebral block',
    infoText: 'Paravertebral blocks provide anesthesia for thoracic and abdominal surgeries. They target the spinal nerves in the paravertebral space. Key anatomical landmarks include the spinous processes, transverse processes, and pleura.',
    url: '',
    images: [],
    videos: [
      {
        src: 'D92ZbS8fMY0',
        title: 'Paravertebral Block',
      },
    ],
  },
  {
    id:  '07f9e86b-6d5e-4a32-8316-5e5e5e5e5e5e',
    name: 'Intercostal block',
    infoText: 'Indications: Surgeries involving the chest wall and rib fractures, Transducer position: Transverse to the chest, at the level of the desired intercostal space, Goal: Local anesthetic spread around the intercostal nerves, Local anesthetic: 3-5 mL per space',
    generalInfo: 'An intercostal block provides anesthesia to the chest wall. It targets the intercostal nerves and involves the administration of local anesthetic around the nerves in the intercostal spaces. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic. The procedure has a low risk of complications and is generally well-tolerated by patients. However, it carries a risk of pneumothorax if the needle is inserted too deeply.',    url: '',
    images: [],
    videos: [],
  },
  {
    id:  'f9ac0a66-8e42-43a7-90f0-6e2b6b3b6b3b',
    name: 'Erector Spinae Block',
    infoText: 'Indications: Surgeries involving the thoracic and abdominal wall, Transducer position: Longitudinal to the spine, at the level of the desired thoracic or lumbar vertebra, Goal: Local anesthetic spread around the erector spinae muscle, Local anesthetic: 20-30 mL',
    generalInfo: 'An erector spinae block provides anesthesia to the thoracic and abdominal wall. It targets the spinal nerves and involves the administration of local anesthetic around the erector spinae muscle. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic. The procedure has a low risk of complications and is generally well-tolerated by patients.',    url: '',
    images: [],
    videos: [
      {
        src: 'EXq5BOBPj8c',
        title: 'Erector Spinae Block',
      },
    ],
  },
  {
    id:   '9ba46d9c-4c4e-4d4f-81f7-6d7b6b3b6b3b',
    name: 'Subcostal TAP block',
    infoText: 'Indications: Surgeries involving the upper abdominal wall, Transducer position: Subcostal, parallel to the costal margin, Goal: Local anesthetic spread between the internal oblique and transversus abdominis muscles, Local anesthetic: 20-30 mL',
    generalInfo: 'A subcostal TAP (transversus abdominis plane) block provides anesthesia to the upper abdominal wall. It targets the anterior branches of the intercostal nerves and involves the administration of local anesthetic between the internal oblique and transversus abdominis muscles. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic. The procedure has a low risk of complications and is generally well-tolerated by patients.',    url: '',
    images: [],
    videos: [
      {
        src: 'OqedcP9OPvc&pp',
        title: 'Subcostal TAP Block',
      }
    ],
  },
  {
    id:   '9ba46d9c-4c4e-4d4f-81f7-6d7b6b3b6b3b',
    name: 'Ilioinguinal/Iliohypogastric block',
    infoText: 'Indications: Surgeries involving the inguinal region and lower abdominal wall, Transducer position: Transverse to the inguinal ligament, Goal: Local anesthetic spread around the ilioinguinal and iliohypogastric nerves, Local anesthetic: 10-15 mL',
    generalInfo: 'An ilioinguinal/iliohypogastric block provides anesthesia to the inguinal region and lower abdominal wall. It targets the ilioinguinal and iliohypogastric nerves and involves the administration of local anesthetic around the nerves. Ultrasound guidance is typically used to ensure accurate placement of local anesthetic. The procedure has a low risk of complications and is generally well-tolerated by patients.',    url: '',
    images: [],
    videos: [
      {
        src: 'WJPH6Ij3StA',
        title: 'Ilioinguinal/Iliohypogastric block',
      }
    ],
  },
  {
    id:   '2f8c0a37-7a8a-4f1d-9e7d-8f6b6c6d6e6f',
    name: 'Rectus sheath block',
    infoText: 'Rectus sheath blocks provide analgesia for midline abdominal surgeries. They target the anterior branches of spinal nerves. Key anatomical landmarks include the rectus abdominis muscle, linea alba, and rectus sheath.',
    url: '',
    images: [],
    videos: [
      {
        src: 'WOyTeZK4Ys8',
        title: 'Rectus Sheath Block',
      },
    ],
  },
  {
    id:  '523a27a1-7f77-46a2-8e5d-6c6d6e6f6e6e',
    name: 'TAP Block',
    infoText: 'TAP blocks are used for analgesia in lower abdominal surgeries. They target the anterior branches of spinal nerves. Key anatomical landmarks include the transversus abdominis muscle, internal oblique muscle, and external oblique muscle.',
    url: '',
    images: [],
    videos: [
      {
        src: 'XgWN-ACbHPE',
        title: 'TAP Block',
      },
    ],
  },
  {
    id: 'a1b2c3d4-e5f6-47a8-89b0-1c2d3e4f5g6h',
    name: 'Local Anaesthetic to Port Sites',
    infoText: 'Local anaesthetic to port sites is a surgical technique used for providing analgesia after laparoscopic surgeries. It involves infiltrating local anaesthetic agents directly into the incision sites where trocars or other laparoscopic instruments were inserted. This helps in reducing postoperative pain and discomfort. Common local anaesthetics used for infiltration include lidocaine, bupivacaine, and ropivacaine.',
    url: '',
    images: [],
    videos: [
    {
    src: '12345-ABCDE',
    title: 'Local Anaesthetic to Port Sites',
    },
    ],
    },
  {
    id: 'e2a6fbb7-1c45-4a7d-9c6b-7d7e7f7e7f7e',
    name: 'Lumbar plexus block',
    infoText: 'Lumbar plexus blocks provide anesthesia for hip and thigh surgeries. They target the lumbar plexus nerves. Key anatomical landmarks include the lumbar vertebrae, psoas major muscle, and quadratus lumborum muscle.',
    url: '',
    images: [],
    videos: [
      {
        src: 'i3AXB6mAoms',
        title: 'Lumbar Plexus Block',
      },
    ],
  },
  {
    id: '0e6b0bf6-8d6e-4c9a-9d2c-8b8c8d8e8f8e',
    name: 'Femoral nerve block',
    infoText: 'Femoral nerve blocks provide analgesia for thigh and knee surgeries. They target the femoral nerve. Key anatomical landmarks include the femoral artery, femoral vein, and inguinal ligament.',
    url: '',
    images: [],
    videos: [
      {
        src: 'tMy978ZwaDU',
        title: 'Femoral Nerve Block',
      },
    ],
  },
  {
    id: 'a3d8b1e9-9b9a-4dce-b0e8-9f9e9f9e9f9e',
    name: 'Popliteal sciatic nerve block',
    infoText: 'Popliteal sciatic nerve blocks provide anesthesia for lower leg and foot surgeries. They target the sciatic nerve. Key anatomical landmarks include the popliteal fossa, biceps femoris muscle, and semitendinosus muscle.',
    url: '',
    images: [],
    videos: [
      {
        src: 'lUkTv5U0F18',
        title: 'Popliteal Sciatic Nerve Block',
      },
    ],
  },
  {
    id: 'fa2c7561-4e1c-4f8d-98be-afb0b1b2b3b4',
    name: 'Fascia iliaca block',
    infoText: 'Fascia iliaca blocks provide analgesia for hip and thigh surgeries. They target the lumbar plexus nerves. Key anatomical landmarks include the inguinal ligament, iliac crest, and iliopsoas muscle.',
    url: '',
    images: [],
    videos: [
      {
        src: 'T7Mp4t08tT8',
        title: 'Fascia Iliaca Block',
      },
    ],
  },
  {
    id: '2e4c9b4e-2d2e-4f3f-934e-bf5f5f5f5f5f',
    name: 'Adductor canal block',
    infoText: 'Adductor canal blocks provide analgesia for knee surgeries. They target the saphenous nerve. Key anatomical landmarks include the adductor canal, femoral artery, and sartorius muscle.',
    url: '',
    images: [],
    videos: [
      {
        src: 'DZLjNHkbMtI',
        title: 'Adductor Canal Block',
      },
    ],
  },
];

  
export default blocksData;

export const fetchBlockData = (name) => {
  const block = blocksData.find((block) => block.name === name);
  return block;
}