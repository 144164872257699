import React from 'react';
import { useParams } from 'react-router-dom';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed.js';
import './BlockDetails.css';

const BlockDetails = ({ blockData }) => {
  const { blockName } = useParams();
  const block = blockName ? blockData(blockName) : null;

  if (!block) {
    return <div>Block not found</div>;
  }

  return (
    <div className="block-details">
      <h2>{block.name}</h2>
      <p>{block.infoText}</p>
      {block.videos.map((video, index) => (
        <YoutubeEmbed key={index} embedId={video.src} title={video.title} />
      ))}
    </div>
  );
};

export default BlockDetails;

