import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { OperationContext } from '../../utils/providers';
import './Results.css';

const Results = ({ selectedOperation }) => {
  if (!selectedOperation || !selectedOperation.blocks) {
    return <div>Enter Operation</div>;
  }

  return (
    <div className="results">
      <div className="result" key={selectedOperation.id}>
        <div className="result-banner">{selectedOperation.name}</div>
        <div>{selectedOperation.description}</div>
        <div> - <strong>Specialty: </strong> {selectedOperation.specialty}</div>
        <div> - <strong>Duration: </strong> {selectedOperation.duration}</div>
        <div> - <strong>Pain: </strong>{selectedOperation.pain}</div>
        <div> - <strong>Block Options: </strong> Options:</div>
          <ul>
            {selectedOperation.blocks.split(', ').map((block, index) => (
              <li key={index}>
                <Link to={`/block/${block}`}>{block}</Link>
              </li>
            ))}
          </ul>
        <div> - <strong>Anaesthetic: </strong>{selectedOperation.anaesthetic}</div>
        <div> - <strong>More info: </strong>More info: {selectedOperation.other}</div>
      </div>
    </div>
  );
};

export default Results;










